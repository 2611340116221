import React from 'react';
import { get } from 'lodash';
import { receiveFail } from '../donations/donations.actions';
import * as Queries from '../../library/query.module';
import { rebuildApiHeaders } from '../../library/functions';
import { getFundraisers } from '../../../api/FundraisersService';

export const REQUEST_FUNDRAISERS = 'REQUEST_FUNDRAISERS';

export function requestFundraisers(accountIdentifier, headers) {
  return {
    type: REQUEST_FUNDRAISERS,
    account_identifier: accountIdentifier,
    headers,
  };
}

export const SET_FUNDRAISER_QUERY = 'SET_FUNDRAISER_QUERY';

export function setFundraiserQuery(query) {
  return {
    type: SET_FUNDRAISER_QUERY,
    query,
  };
}

export const RECEIVE_FUNDRAISERS = 'RECEIVE_FUNDRAISERS';

export function receiveFundraisers(accountIdentifier, json) {
  return {
    type: RECEIVE_FUNDRAISERS,
    account_identifier: accountIdentifier,
    fundraisers: json.data,
    summary: json.summary,
    orderBy: json.params,
    receivedAt: Date.now(),
  };
}

export const CLEAR_FUNDRAISERS = 'CLEAR_FUNDRAISERS';

export function clearFundraisers(accountIdentifier, json) {
  console.log('clearFundraisers');
  return {
    type: CLEAR_FUNDRAISERS,
    account_identifier: accountIdentifier,
    fundraisers: [],
    summary: json.summary,
    orderBy: json.params,
    receivedAt: null,
  };
}

export const REQUEST_FUNDRAISER = 'REQUEST_FUNDRAISER';

export function requestFundraiser(accountIdentifier, headers, fundraiserId) {
  return {
    type: REQUEST_FUNDRAISER,
    account_identifier: accountIdentifier,
    headers,
    fundraiserId,
  };
}

export const SELECTED_FUNDRAISER = 'SELECTED_FUNDRAISER';

export function selectedFundraiser(fundraiser) {
  return {
    type: SELECTED_FUNDRAISER,
    fundraiser,
  };
}

export const INVALIDATE_FUNDRAISER = 'INVALIDATE_FUNDRAISER';

export function invalidateFundraiser(fundraiser) {
  return {
    type: INVALIDATE_FUNDRAISER,
    fundraiser,
  };
}

export const EDIT_FUNDRAISER = 'EDIT_FUNDRAISER';

export function editFundraiser(fundraiserId, headers) {
  return {
    type: EDIT_FUNDRAISER,
    fundraiserId,
    headers,
  };
}

export const RECEIVE_EXPORT = 'RECEIVE_EXPORT';

export function receiveExport(message) {
  return {
    type: RECEIVE_EXPORT,
    message,
  };
}

export const RECEIVE_FUNDRAISERS_RECURSIVELY =
  'RECEIVE_FUNDRAISERS_RECURSIVELY';

export function receiveFundraisersRecursively(fundraisers, accountIdentifier) {
  return {
    type: RECEIVE_FUNDRAISERS_RECURSIVELY,
    account_identifier: accountIdentifier,
    fundraisers,
  };
}

export const INIT_FUNDRAISERS_FOR_ACCOUNT = 'INIT_FUNDRAISERS_FOR_ACCOUNT';

export function initFundraisersForAccount(accountIdentifier) {
  return {
    type: INIT_FUNDRAISERS_FOR_ACCOUNT,
    account_identifier: accountIdentifier,
  };
}

export const UPDATE_RECURSIVE_FUNDRAISER_PROGRESS =
  'UPDATE_RECURSIVE_FUNDRAISER_PROGRESS';

export function updateRecursiveFundraiserProgress(accountIdentifier, message) {
  return {
    type: UPDATE_RECURSIVE_FUNDRAISER_PROGRESS,
    account_identifier: accountIdentifier,
    message,
  };
}

/**
 * Export Fundraisers
 * Note: This function is out of dispatch behaivor
 */
export const exportFundraisers = (
  accountIdentifier,
  headers,
  offset = '0',
  query = { status: 'all' }
) => {
  let queryVal = query;

  if (queryVal && !queryVal.hasOwnProperty('status')) {
    queryVal = { ...queryVal, status: 'all' };
  }

  const url = Queries.getFundraisersQuery(accountIdentifier, offset, queryVal);

  return fetch(url, {
    method: 'GET',
    json: true,
    rejectUnauthorized: false,
    headers: rebuildApiHeaders(headers, '2021-11-15'),
  });
};

export function fetchFundraisers(
  accountIdentifier,
  headers,
  offset = 0,
  query = { status: 'all', limit: 100 }
) {
  let queryVal = query;

  if (queryVal && !queryVal.hasOwnProperty('status')) {
    queryVal = { ...queryVal, status: 'all' };
  }

  // console.log('fetchFundraisers', {accountIdentifier}, {headers}, {queryVal})

  const url = Queries.getFundraisersQuery(accountIdentifier, offset, queryVal);
  const options = {
    urlQuery: url.search,
    headers,
  };

  if (queryVal.hasOwnProperty('export_columns')) {
    return async dispatch => {
      const data = await getFundraisers(options);
      if (!data) {
        dispatch(receiveExport('error'));
      } else if (data) {
        dispatch(receiveExport('success'));
      }
    };
  }

  return async dispatch => {
    dispatch(requestFundraisers(accountIdentifier, headers));
    const data = await getFundraisers(options);
    if (!data) {
      console.log('error');
    }
    dispatch(receiveFundraisers(accountIdentifier, data));
    dispatch(setFundraiserQuery(queryVal));
  };
}

export function fetchFundraiser(accountIdentifier, headers, fundraiserId) {
  // console.log('fetchFundraiser', {accountIdentifier}, {headers}, {fundraiserId})
  return dispatch => {
    dispatch(requestFundraiser(accountIdentifier, headers, fundraiserId));
    return fetch(
      `${process.env.DONATELY_API_V2_URL}/fundraisers/${fundraiserId}.json?account_id=${accountIdentifier}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers, '2021-11-15'),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        console.log('fetchFundraiser error');
      } else {
        return response
          .json()
          .then(json => dispatch(selectedFundraiser(json.data)));
      }
    });
  };
}

export function updateFundraiser(
  accountIdentifier,
  headers,
  fundraiserId,
  updateValues
) {
  return dispatch => {
    dispatch(editFundraiser(fundraiserId, headers));
    return fetch(
      `${process.env.DONATELY_API_V2_URL}/fundraisers/${fundraiserId}.json?account_id=${accountIdentifier}`,
      {
        method: 'POST',
        body: JSON.stringify(updateValues),
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers, '2021-11-15'),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }

      return response
        .json()
        .then(json => dispatch(selectedFundraiser(json.data)))
        .then(dispatch(fetchFundraisers(accountIdentifier, headers)));
    });
  };
}

export function shouldFetchFundraisers(state, accountIdentifier) {
  const fundraisers =
    state.fundraiserReducer.fundraisersByAccount[accountIdentifier];
  if (!fundraisers) {
    return true;
  }
  if (fundraisers.isFetching || fundraisers.items) {
    return false;
  }
}

export function fetchFundraisersIfNeeded(accountIdentifier, headers, offset) {
  return (dispatch, getState) => {
    if (shouldFetchFundraisers(getState(), accountIdentifier)) {
      return dispatch(fetchFundraisers(accountIdentifier, headers, offset));
    }
  };
}

export function destroyFundraiser(accountIdentifier, headers, FundraiserId) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/fundraisers/${FundraiserId}.json?account_id=${accountIdentifier}`,
      {
        method: 'DELETE',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers, '2021-11-15'),
      }
    ).then(function(response) {
      return response
        .json()
        .then(dispatch(fetchFundraisers(accountIdentifier, headers)));
    });
}

export function fetchAllFundraisersRecursively(accountIdentifier, headers) {
  return fetchFundraisersRecursively(accountIdentifier, headers);
}

export function fetchFundraisersRecursively(
  accountIdentifier,
  headers,
  query = {}
) {
  return async (dispatch, getState) => {
    let currentState = getState();
    let fundraisersForAccountFromReducer = get(
      currentState,
      `fundraiserReducer.fundraisersByAccount.${accountIdentifier}`,
      {}
    );
    const finalQuery = {
      ...{ status: 'published', order: 'ASC', order_by: 'title' },
      ...query,
    };

    const getUrlFromLimitOffset = (off, lim) => {
      const queryVal = { ...finalQuery, limit: lim };
      return Queries.getFundraisersQuery(accountIdentifier, off, queryVal);
    };

    // if fundraiser object is not initialized.
    if (Object.keys(fundraisersForAccountFromReducer).length === 0) {
      await dispatch(initFundraisersForAccount(accountIdentifier));
    }

    currentState = getState();
    fundraisersForAccountFromReducer = get(
      currentState,
      `fundraiserReducer.fundraisersByAccount.${accountIdentifier}`,
      {}
    );
    if (fundraisersForAccountFromReducer.isFetching) {
      return;
    }
    if (fundraisersForAccountFromReducer.items.length > 0) {
      // check if the current number of fundraisers is lesser than the total.
      const pollingResponse = await fetch(getUrlFromLimitOffset(0, 1), {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers, '2021-11-15'),
      });
      if (pollingResponse.status === 200) {
        const pollingJson = await pollingResponse.json();

        if (
          get(pollingJson, 'summary.total_count', 0) <=
          fundraisersForAccountFromReducer.items.length
        ) {
          return;
        }
      }
    }

    // make the batch size 100

    // first
    let shouldFetch = true;
    let currentBatchFundraisers = [];
    let totalFundraisers = [];

    const limit = 100;
    let offset = 0;
    let errorCounter = 0;
    let totalCount = 0;

    await dispatch(requestFundraisers(accountIdentifier, headers));
    const isDonor = localStorage.getItem('isDonor');

    while (shouldFetch) {
      const response = await fetch(getUrlFromLimitOffset(offset, limit), {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers:
          isDonor === 'true' ? {} : rebuildApiHeaders(headers, '2021-11-15'),
      });
      if (response.status === 200) {
        const fundraisersJson = await response.json();
        offset += limit;
        currentBatchFundraisers = fundraisersJson.data;
        totalFundraisers = [...totalFundraisers, ...currentBatchFundraisers];
        totalCount = get(fundraisersJson, 'summary.total_count', 0);
        await dispatch(
          updateRecursiveFundraiserProgress(
            accountIdentifier,
            `Retrieved ${totalFundraisers.length} of ${totalCount} fundraisers`
          )
        );
        shouldFetch = totalFundraisers.length < totalCount;
      } else {
        errorCounter += 1;
        dispatch(
          receiveFail(`Error fetching fundraisers: ${response.message}`)
        );
        shouldFetch = errorCounter <= 10;
      }
    }

    dispatch(
      receiveFundraisersRecursively(totalFundraisers, accountIdentifier)
    );

    return totalFundraisers;
  };
}

export function makeFundraiserTeamLeader(fundraiserId) {
  return async (dispatch, getState) => {
    const {
      accountReducer: { storeUser: headers, selectedAccount },
      fundraiserReducer: { selectedFundraiser: currentFundraiser },
    } = getState();
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/fundraisers/${fundraiserId}?make_team_leader=true&account_id=${selectedAccount.id}`,
      {
        method: 'POST',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers, '2021-11-15'),
      }
    );
    const json = await response.json();
    if (response.status === 200) {
      dispatch(
        selectedFundraiser({
          ...currentFundraiser,
          team_leader: true,
        })
      );
    } else {
      console.error('An error happened with the request. Info ', json);
    }
  };
}
