import { combineReducers } from 'redux';

import accountReducer from './authenticated/user-accounts/user-accounts.reducers';
import donationReducer from './authenticated/donations/donations.reducers';
import campaignReducer from './authenticated/campaigns/campaigns.reducers';
import pageTemplatesReducer from './authenticated/campaigns/page-templates.reducers';
import fundraiserReducer from './authenticated/fundraisers/fundraisers.reducers';
import formsReducer from './authenticated/forms3/store_management/forms.reducers';
import formsReducerv1 from './authenticated/forms/forms.reducers';
import htmlContentsReducer from './authenticated/forms3/store_management/html-contents.reducer';
import donorReducer from './authenticated/donors/donors.reducers';
import settingsReducer from './authenticated/account-settings/account-settings.reducers';
import myProfileReducer from './authenticated/my-profile/my-profile.reducers';
import subscriptionReducer from './authenticated/subscriptions/subscription.reducers';
import dashboardReducer from './authenticated/dashboard/dashboard.reducers';
import integrationReducer from './authenticated/integrations/integrations.reducers';
import textEngagementReducer from './authenticated/text-engagement/text-engagement.reducers';
import modalReducer from './authenticated/modal/modal.reducers';

const reducers = combineReducers({
  accountReducer,
  donationReducer,
  campaignReducer,
  fundraiserReducer,
  donorReducer,
  settingsReducer,
  myProfileReducer,
  subscriptionReducer,
  formsReducer,
  formsReducerv1,
  dashboardReducer,
  integrationReducer,
  textEngagementReducer,
  modalReducer,
  htmlContentsReducer,
  pageTemplatesReducer,
});

export default reducers;
