import React, { Suspense, useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import AppContext from './AppContext';
import EventManager from './EventManager';
import UserAccountsContainer from './authenticated/user-accounts/user-accounts.container';
import SideNav from './authenticated/user-accounts/components/side-nav.component';
import { isDonor } from './authenticated/user-accounts/user-accounts.actions';
import Loading from './library/components/Loading/loading.component';
import {
  sessionInitialize,
  sessionRefresh,
  sessionRefreshOnboarding,
} from './unauthenticated/actions/session-initialize.actions';
import Modal from './library/components/modal.component';
import { hideModal } from './authenticated/modal/modal.actions';
import { parseUrl, consoleLog } from './library/functions';
import ProductionFallbackContainer from './library/components/production-fallback-container';
import DevelopmentFallbackContainer from './library/components/development-fallback-container';
import UpgradeModal from './library/components/Upgrade/UpgradeModal';

const url = require('url');

const getUserHeaders = function(s) {
  return s.getState().accountReducer.storeUser;
};

const getSelectedAccount = function(s) {
  return s.getState().accountReducer.selectedAccount;
};

const getCurrentUserSettings = function(s) {
  return s.getState().myProfileReducer;
};

const isDonorState = function(s) {
  return s.getState().accountReducer.isDonor;
};

const donorRoute = path => path === '/my_profile';

const debugRouteHistory = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { location } = useHistory();
  const { pathname } = location;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [lastRoute, setLastRoute] = useState('');
  if (pathname !== lastRoute) {
    setLastRoute(pathname);
    // consoleLog('-> AppContainer | Change Route:', { lastRoute }, { pathname });
  }
};

export function UnconnectedAuthenticatedRoute({
  path,
  auth,
  store,
  renderSideNav,
  children,
  component,
  ...routeProps
}) {
  // This is the lazy loaded component that should be rendered on Suspense.
  const Component = component;

  const history = useHistory();
  const { isAuthenticated, isLoading } = useAuth0();

  const dispatch = useDispatch();
  const { selectedAccount, accountsByUser } = useSelector(
    state => state.accountReducer
  );

  const {
    open: modalOpen,
    text: modalText,
    onAgree: modalOnAgree,
    export: modalExport,
    noCancel: modalNoCancel,
  } = useSelector(state => state.modalReducer);

  const [sendHubspotAnalytics, setSendHubspotAnalytics] = useState(false);

  // ensure correct header is rendering for account or donor dash
  if (donorRoute(path) && isDonorState(store) === 'false') {
    store.dispatch(isDonor('true'));
  } else if (
    !donorRoute(path) &&
    isDonorState(store) === 'true' &&
    getSelectedAccount(store)
  ) {
    store.dispatch(isDonor('false'));
  }

  useEffect(() => {
    // note: admins should have at least one accountsByUser,
    //       but non-admins don't need any accountsByUser

    // consoleLog(
    //   `UnconnectedAuthenticatedRoute`,
    //   { isAuthenticated },
    //   { isLoading },
    //   { selectedAccount },
    //   { codeArea: 'routes' }
    // );

    if (isAuthenticated) {
      if (isDonorState(store) === 'false') {
        if (window.location.pathname === '/onboarding') {
          dispatch(sessionRefreshOnboarding());
        } else if (!selectedAccount) {
          dispatch(sessionInitialize());
        } else if (Object.keys(accountsByUser).length === 0) {
          dispatch(sessionRefresh());
        }
      }
    } else if (!isLoading) {
      // user is not authenticated and isn't loading
      history.push('/login');
    }
  }, [isAuthenticated, selectedAccount, accountsByUser, isLoading]);

  useEffect(() => {
    if (!sendHubspotAnalytics) {
      if (window._hsq) {
        window._hsq.push(['setPath', window.location.pathname]);
        setSendHubspotAnalytics(true);
      }
    }
  });

  debugRouteHistory();

  if (isLoading || !isAuthenticated) {
    return null;
  }

  return (
    <>
      <Route
        {...routeProps}
        render={() => (
          <AppContext.Provider
            value={{
              auth,
              selectedAccount: getSelectedAccount(store),
              currentUserSettings: getCurrentUserSettings(store),
              userHeaders: getUserHeaders(store),
              eventManager: EventManager,
            }}
          >
            <div className="home-wrapper">
              <div>
                <UserAccountsContainer auth={auth} />
              </div>
            </div>
            {renderSideNav && <SideNav />}
            <ErrorBoundary
              FallbackComponent={
                process.env.NODE_ENV === 'production'
                  ? ProductionFallbackContainer
                  : DevelopmentFallbackContainer
              }
            >
              {/* Render the Component with Suspense if provided; otherwise return the children (original behavior) */}
              {component ? (
                <Suspense
                  fallback={
                    <div>
                      <div className="h-24 flex items-center w-full">
                        <div className="ml-4 text-3xl text-black pt-6 font-medium" />
                      </div>
                      <div className="page-content inline-block pl-6">
                        <Loading />
                      </div>
                    </div>
                  }
                >
                  <Component />
                </Suspense>
              ) : (
                children
              )}
            </ErrorBoundary>
          </AppContext.Provider>
        )}
      />
      {modalOpen && (
        <Modal
          noCancel={modalNoCancel}
          modalText={modalText}
          closeFunction={() => dispatch(hideModal())}
          agreeFunction={agreeArgs => {
            if (modalOnAgree) {
              modalOnAgree(agreeArgs);
            }
            dispatch(hideModal());
          }}
          export={modalExport}
        />
      )}
      <UpgradeModal />
    </>
  );
}

export const AuthenticatedRoute = connect()(
  withAuthenticationRequired(UnconnectedAuthenticatedRoute)
);

export function UnAuthenticatedRoute({
  path,
  children,
  component,
  auth,
  ...routeProps
}) {
  const Component = component;
  const [sendHubspotAnalytics, setSendHubspotAnalytics] = useState(false);
  const history = useHistory();
  const urlParams = parseUrl(history.location.search);

  useEffect(() => {
    // only init() in the UnAuthenticatedRoute - we don't need to track inside the Dashboard.
    if (window.DonatelyMarketing) {
      let config = {};
      let publicPaths = null;
      if (
        typeof process === 'object' &&
        process.env.NODE_ENV !== 'production'
      ) {
        publicPaths = url.parse(process.env.REACT_APP_PUBLIC_URL);
        config = { config: { 'domain-base': publicPaths.hostname } };
        // console.log('publicPaths', config, {publicPaths})
      }
      window.DonatelyMarketing.init(config);
    }

    if (!sendHubspotAnalytics) {
      if (window._hsq) {
        window._hsq.push(['setPath', window.location.pathname]);
        // consoleLog(`HubspotAnalytics | setPath: ${window.location.pathname}`, {codeArea: 'routes'});
        setSendHubspotAnalytics(true);
      }
    }
  });

  // consoleLog(
  //   `UnAuthenticatedRoute`,
  //   { urlParams },
  //   { auth },
  //   {codeArea: 'routes'}
  // );

  return (
    <Route
      {...routeProps}
      render={({ location }) => {
        // Render the Component with Suspense if provided; otherwise return the children (original behavior)
        if (component) {
          return (
            <Suspense fallback={<div>loading...</div>}>
              <ErrorBoundary>
                <Component auth={auth} />
              </ErrorBoundary>
            </Suspense>
          );
        }

        return <ErrorBoundary>{children}</ErrorBoundary>;
      }}
    />
  );
}
