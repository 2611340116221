import { combineReducers } from 'redux';

export const REQUEST_PAGE_TEMPLATES = 'REQUEST_PAGE_TEMPLATES';
export const RECEIVE_PAGE_TEMPLATES = 'RECEIVE_PAGE_TEMPLATES';
export const ADD_TO_PAGE_TEMPLATES = 'ADD_TO_PAGE_TEMPLATES';

export function pageTemplates(
  state = {
    isFetching: false,
    items: [],
  },
  action
) {
  switch (action.type) {
    case REQUEST_PAGE_TEMPLATES:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_PAGE_TEMPLATES:
      return {
        ...state,
        isFetching: false,
        items: action.pageTemplates,
      };
    case ADD_TO_PAGE_TEMPLATES:
      return {
        ...state,
        isFetching: false,
        items: [...state.items, ...action.pageTemplates],
      };
    default:
      return state;
  }
}

const pageTemplatesReducer = combineReducers({
  pageTemplates,
});

export default pageTemplatesReducer;
